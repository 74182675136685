import { lazy } from "react";

const SharedLayout = lazy(() => import("./SharedLayout"));
const BottomNavigation = lazy(() => import("./BottomNavigation"));
const ChipButton = lazy(() => import("./ChipButton"));
const FilterBar = lazy(() => import("./FilterBar"));
const EmptyPlaceholder = lazy(() => import("./EmptyPlaceholder"));
const Divider = lazy(() => import("./Divider"));
const HeaderBack = lazy(() => import("./HeaderBack"));

export {
  SharedLayout,
  BottomNavigation,
  ChipButton,
  FilterBar,
  EmptyPlaceholder,
  Divider,
  HeaderBack
};
