export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";

export const setUsers = (users) => {
  return {
    type: SET_USERS,
    payload: users,
  };
};

export const getUsers = () => {
  return {
    type: GET_USERS,
  };
};
