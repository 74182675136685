import { GET_USERS, SET_USERS } from "../actions/userActions";

const UserReducer = (state = [], action) => {
  switch (action.type) {
    case SET_USERS:
      return action.payload;

    case GET_USERS:
      return state;

    default:
      return state;
  }
};

export default UserReducer;
