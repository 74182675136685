export const GET_ORDERS = "GET_ORDERS";
export const SET_ORDERS = "SET_ORDERS";

export const setOrders = (orders) => {
  return {
    type: SET_ORDERS,
    payload: orders,
  };
};

export const getOrders = () => {
  return {
    type: GET_ORDERS,
  };
};
